import React, { useRef, useState, useEffect, forwardRef, useCallback, useMemo, } from 'react';
import { View, TextInput, TouchableOpacity, Platform, InteractionManager, } from 'react-native';
import _ from 'lodash';
import Animated, { withTiming, useSharedValue, useDerivedValue, useAnimatedStyle, Easing, interpolate, } from 'react-native-reanimated';
import Text from 'design-system/Text';
import { rem } from 'design-system/values';
import useColors from 'hooks/useColors';
import useFocusEffect from 'hooks/useFocusEffect';
import useStyles from 'hooks/useStyles';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import createStyleSheets from 'utils/createStyleSheets';
import { isWeb } from '../constants';
import IconClose from './icons/IconClose';
import IconSearch from './icons/IconSearch';
const HIT_SLOP = { left: 10, right: 10, top: 10, bottom: 10 };
const strings = {
    search: 'Search...',
    cancel: 'Cancel',
};
export const SearchBar = forwardRef(({ onChangeSearchTerm, noDebounce, forSearchHeader, placeholder = 'Search', onFocus: onFocusFromProps, onCancel: onCancelFromProps, alwaysShowCancel, textFieldBackgroundColor, textColor, iconColor, lightModeOnly, textInputBgStyles, focusOnScreenFocus = true, isInTab, isInNativeModal, containerStyle, noAnimation, }, ref) => {
    const { width } = useAppFrameDimensions(isInTab);
    const searchBarRef = useRef(null);
    const cancelWidth = useSharedValue(0);
    const cancelShownProgress = useSharedValue(0);
    const derivedCancelProgressValue = useDerivedValue(() => withTiming(cancelShownProgress.value, {
        duration: 150,
        easing: Easing.ease,
    }), []);
    const [searchTerm, setSearchTerm] = useState('');
    const focus = useCallback(() => {
        searchBarRef.current?.focus();
    }, []);
    useFocusEffect(useCallback(() => {
        if (searchTerm && focusOnScreenFocus)
            focus();
    }, [focus, focusOnScreenFocus, searchTerm]));
    const blur = useCallback(() => {
        searchBarRef.current?.blur();
    }, []);
    useEffect(() => {
        if (typeof ref === 'function') {
            ref({ focus, blur });
        }
        else if (ref) {
            // eslint-disable-next-line no-param-reassign
            ref.current = { focus, blur };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blur, focus]);
    const debouncedOnChangeText = useRef(_.debounce(onChangeSearchTerm, 300)).current;
    const handleTextChange = (newText) => {
        setSearchTerm(newText);
        if (noDebounce) {
            onChangeSearchTerm(newText);
        }
        else {
            debouncedOnChangeText(newText);
        }
    };
    const setCancel = useCallback((value) => {
        cancelShownProgress.value = value;
    }, [cancelShownProgress]);
    const onFocus = useCallback(() => {
        onFocusFromProps?.();
        InteractionManager.runAfterInteractions(() => {
            setCancel(1);
        });
    }, [onFocusFromProps, setCancel]);
    const onBlur = useCallback(() => {
        if (!alwaysShowCancel && cancelShownProgress.value !== 0) {
            setCancel(0);
        }
    }, [alwaysShowCancel, cancelShownProgress.value, setCancel]);
    const onClearSearch = useCallback(() => {
        setSearchTerm('');
        onChangeSearchTerm('');
    }, [onChangeSearchTerm]);
    const timeoutRef = useRef();
    const onCancel = useCallback(() => {
        setSearchTerm('');
        if (alwaysShowCancel) {
            blur();
            onChangeSearchTerm('');
            onCancelFromProps?.();
            return;
        }
        setCancel(0);
        timeoutRef.current = setTimeout(() => {
            blur();
            onChangeSearchTerm('');
            onCancelFromProps?.();
        }, 250);
    }, [
        alwaysShowCancel,
        blur,
        onCancelFromProps,
        onChangeSearchTerm,
        setCancel,
    ]);
    useEffect(() => () => {
        clearTimeout(timeoutRef.current);
    }, []);
    const onCancelLayout = useCallback((event) => {
        if (!cancelWidth.value) {
            cancelWidth.value = event.nativeEvent.layout.width;
        }
    }, [cancelWidth]);
    const searchBarAnimatedStyle = useAnimatedStyle(() => ({
        marginRight: interpolate(derivedCancelProgressValue.value, [0, 1], [0, cancelWidth.value]),
    }), []);
    const [nativeModalWidth, setNativeModalWidth] = useState();
    const animatedCancelStyles = useAnimatedStyle(() => {
        const usableWidth = nativeModalWidth || width;
        return {
            left: interpolate(derivedCancelProgressValue.value, [0, 1], [usableWidth, usableWidth - cancelWidth.value]),
            opacity: interpolate(derivedCancelProgressValue.value, [0, 1], [0, 1]),
        };
    }, [nativeModalWidth, width]);
    const onLayoutChange = useCallback((event) => {
        setNativeModalWidth(event.nativeEvent.layout.width);
    }, []);
    const colors = useColors(lightModeOnly);
    const styles = useStyles(styleSet, lightModeOnly);
    const textInputStyles = useMemo(() => [styles.searchInput, textColor ? { color: textColor } : undefined], [styles.searchInput, textColor]);
    const textInputBg = useMemo(() => [
        styles.searchBar,
        textInputBgStyles || {
            backgroundColor: textFieldBackgroundColor ?? colors.cards.onLight,
        },
    ], [
        colors.cards.onLight,
        styles.searchBar,
        textFieldBackgroundColor,
        textInputBgStyles,
    ]);
    return (<View style={styles.header} onLayout={isInNativeModal ? onLayoutChange : undefined}>
        <Animated.View style={[
            styles.searchBarContainer,
            !noAnimation && searchBarAnimatedStyle,
            !!forSearchHeader && styles.croppedPaddingTop,
            containerStyle,
        ]}>
          <Animated.View style={textInputBg}>
            <View style={styles.searchIconContainer}>
              <IconSearch color={iconColor}/>
            </View>
            <TextInput onBlur={onBlur} autoFocus={false} onFocus={onFocus} spellCheck={false} ref={searchBarRef} value={searchTerm} autoCorrect={false} autoComplete="off" placeholder={placeholder} style={textInputStyles} onChangeText={handleTextChange} selectionColor={colors.text.selection} placeholderTextColor={textColor || colors.text.secondary}/>
            {!!searchTerm && (<TouchableOpacity onPress={onClearSearch} style={styles.closeIcon}>
                <IconClose color={iconColor || colors.text.secondary}/>
              </TouchableOpacity>)}
          </Animated.View>
          <Animated.View style={[
            styles.cancelContainer,
            !!forSearchHeader && styles.croppedTop,
            animatedCancelStyles,
        ]} onLayout={onCancelLayout}>
            <TouchableOpacity onPress={onCancel} hitSlop={HIT_SLOP}>
              <Text Text-14 color={textColor || colors.text.secondary}>
                {strings.cancel}
              </Text>
            </TouchableOpacity>
          </Animated.View>
        </Animated.View>
      </View>);
});
const BORDER_RADIUS = (() => rem(12))();
export const SEARCH_BAR_HEIGHT = (() => rem(48))();
export const SEARCH_BAR_HEIGHT_RAW = 48;
const PADDING_HORIZONTAL = (() => rem(16))();
const CLEAR_TOUCH_ZONE_WIDTH = (() => rem(56))();
export const SEARCH_BAR_MARGIN_VERTICAL = (() => rem(12))();
export const SEARCH_BAR_TOTAL_HEIGHT = SEARCH_BAR_HEIGHT + SEARCH_BAR_MARGIN_VERTICAL;
const styleSet = createStyleSheets((colors) => ({
    header: {
        flexDirection: 'row',
        overflow: Platform.select({
            android: 'visible',
            default: 'hidden',
        }),
    },
    searchBarContainer: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: PADDING_HORIZONTAL,
        paddingVertical: SEARCH_BAR_MARGIN_VERTICAL,
    },
    searchBar: {
        flexDirection: 'row',
        alignItems: 'center',
        height: SEARCH_BAR_HEIGHT,
        borderRadius: BORDER_RADIUS,
        backgroundColor: colors.cards.onLight,
    },
    searchInput: {
        flex: 1,
        padding: 0,
        fontSize: rem(16),
        color: colors.text.primary,
        fontFamily: 'Montserrat-Light',
        ...(isWeb && {
            caretColor: colors.text.selection,
        }),
    },
    closeIcon: {
        width: CLEAR_TOUCH_ZONE_WIDTH,
        height: SEARCH_BAR_HEIGHT,
        borderTopRightRadius: BORDER_RADIUS,
        borderBottomRightRadius: BORDER_RADIUS,
        alignItems: 'center',
        justifyContent: 'center',
    },
    row: {
        flexDirection: 'row',
    },
    cancelContainer: {
        position: 'absolute',
        justifyContent: 'center',
        height: SEARCH_BAR_HEIGHT,
        top: SEARCH_BAR_MARGIN_VERTICAL,
        paddingRight: PADDING_HORIZONTAL,
    },
    croppedTop: {
        top: rem(8),
    },
    searchIconContainer: {
        paddingHorizontal: PADDING_HORIZONTAL,
    },
    croppedPaddingTop: {
        paddingTop: rem(8),
    },
}));
export default React.memo(SearchBar);
