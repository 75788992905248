import emoji from 'node-emoji';
import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Alert, Platform, View } from 'react-native';
import TextInput from 'utils/packages/TextInput';
import { AppView } from 'design-system';
import Button from 'design-system/Button';
import Spacer from 'design-system/Spacer';
import StatusBar from 'design-system/StatusBar';
import { styles as textStyles } from 'design-system/Text';
import { rem } from 'design-system/values';
import { getCategories } from 'actions';
import KeyboardAvoidingView from 'design-system/KeyboardAvoidingView';
import { useAutoFocusInput } from 'hooks/useAutoFocusInput';
import useColors from 'hooks/useColors';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useStyles from 'hooks/useStyles';
import { useAppDispatch } from 'store/hooks';
import createStyleSheets from 'utils/createStyleSheets';
import { isWeb } from '../../../constants';
const strings = {
    screenTitle: 'Add a reference',
    placeholder: 'Reference',
    next: 'Next',
    errorTitle: 'Slow down :face_with_rolling_eyes:',
    errorMessage: 'You must enter a merchant.',
};
const ChooseCounterpartNameScreen = ({ navigation, route }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    useLayoutEffect(() => {
        navigation.setOptions({
            title: strings.screenTitle,
            headerTintColor: colors.text.primary,
        });
    }, [colors.text.primary]);
    const [value, setValue] = useState('');
    const textInputRef = useRef(null);
    const dispatch = useAppDispatch();
    useAutoFocusInput(textInputRef);
    const onSubmit = useCallback(async () => {
        if (value.length < 1) {
            Alert.alert(emoji.emojify(strings.errorTitle), strings.errorMessage);
            return;
        }
        dispatch(getCategories());
        textInputRef.current?.blur();
        navigation.navigate('EditCategory', {
            ...route.params,
            isPickingCategory: true,
            customName: value,
        });
    }, [route.params, value]);
    const onChangeText = useCallback((text) => {
        setValue(text);
    }, []);
    useAutoFocusInput(textInputRef);
    const textInput = useMemo(() => (<TextInput ref={textInputRef} style={[isWeb ? styles.font16 : styles.font26, styles.textInput]} value={value} placeholder={strings.placeholder} placeholderTextColor={colors.text.placeholder} selectionColor={colors.text.selection} underlineColorAndroid="transparent" textAlign="center" autoCapitalize="words" onChangeText={onChangeText} onSubmitEditing={onSubmit} focusStyle={isWeb ? styles.textInputFocus : undefined}/>), [
        styles.font16,
        styles.font26,
        styles.textInput,
        styles.textInputFocus,
        value,
        colors.text.placeholder,
        colors.text.selection,
        onChangeText,
        onSubmit,
    ]);
    const marginBottom = useMarginBottom('marginBottom', undefined, styles.buttonContainer);
    const button = useMemo(() => (<View style={marginBottom}>
        <Button brand title={strings.next} onPress={onSubmit}/>
      </View>), [marginBottom, onSubmit]);
    return (<AppView isInTab={isWeb} avoidHeader={isWeb}>
      <StatusBar barStyle="dark-content" animated/>
      {Platform.OS === 'android' ? (<View style={styles.container}>
          <Spacer flex/>
          {textInput}
          <Spacer flex/>
          {button}
        </View>) : (<KeyboardAvoidingView style={styles.container} behavior="padding">
          {isWeb ? <Spacer h={72}/> : <Spacer flex/>}
          {textInput}
          <Spacer flex/>
          {button}
        </KeyboardAvoidingView>)}
    </AppView>);
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        flex: 1,
    },
    buttonContainer: {
        paddingHorizontal: rem(16),
    },
    font26: {
        fontSize: rem(26),
        color: colors.text.primary,
        lineHeight: undefined,
    },
    font16: {
        fontSize: rem(16),
        color: colors.text.primary,
        lineHeight: undefined,
    },
    textInput: isWeb
        ? { paddingVertical: rem(16), color: colors.text.secondary }
        : {
            ...textStyles.TextThin_20,
            flex: 1,
        },
    textInputFocus: {
        color: colors.text.primary,
    },
}));
export default ChooseCounterpartNameScreen;
