import { useEffect } from 'react';
import { InteractionManager, Platform } from 'react-native';
import { useMainStackNavigation } from 'utils/types/navigationV6';
export const useAutoFocusInput = (inputRef) => {
    const navigation = useMainStackNavigation();
    useEffect(() => {
        const focus = () => {
            if (inputRef?.current) {
                if ('focus' in inputRef.current) {
                    inputRef?.current.focus();
                }
                else if ('getElement' in inputRef.current) {
                    inputRef?.current.getElement().focus();
                }
            }
        };
        if (Platform.OS === 'ios') {
            let timeout;
            const unsubscribe = navigation.addListener('transitionEnd', () => {
                timeout = setTimeout(() => {
                    InteractionManager.runAfterInteractions(() => {
                        focus();
                        unsubscribe?.();
                    });
                });
            });
            return () => {
                if (timeout !== undefined) {
                    clearTimeout(timeout);
                }
                unsubscribe?.();
            };
        }
        const timeout = setTimeout(() => {
            focus();
        });
        return () => {
            clearTimeout(timeout);
        };
    }, [inputRef, navigation]);
};
export const focus = (inputRef) => {
    if (inputRef?.current) {
        if ('focus' in inputRef.current) {
            inputRef?.current.focus();
        }
        else if ('getElement' in inputRef.current) {
            inputRef?.current.getElement().focus();
        }
    }
};
export const blur = (inputRef) => {
    if (inputRef?.current) {
        if ('blur' in inputRef.current) {
            inputRef?.current.blur();
        }
        else if ('getElement' in inputRef.current) {
            inputRef?.current.getElement().blur();
        }
    }
};
