import React, { useCallback, useRef, useState, } from 'react';
import { View, TextInput, TouchableWithoutFeedback, StyleSheet, } from 'react-native';
import { TextInputMask, } from 'react-native-masked-text';
import { rem, hitSlop } from 'design-system/values';
import { IconClose } from 'design-system/icons/IconClose';
import { IconArrow } from 'design-system/icons/IconArrow';
import { Switch } from 'design-system/Switch';
import { SettingsItemIcon } from 'design-system/SettingsItemIcon';
import Text from 'design-system/Text';
import Squircle from 'design-system/Squircle';
import IconCheck from 'design-system/icons/IconCheck';
import BlurAmountView from 'design-system/BlurAmountView';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import { isDesktop, isWeb } from '../constants';
import Indicator from './Indicator';
export const SettingsItemContent = ({ children, style, ...props }) => {
    const styles = useStyles(settingsItemStyleSet);
    return (<View style={[styles.contentContainer, style]} {...props}>
      {children}
    </View>);
};
export const SettingsItemRightContent = ({ children, style, ...props }) => {
    const styles = useStyles(settingsItemStyleSet);
    return (<View style={[styles.rightContentContainer, style]} {...props}>
      {children}
    </View>);
};
export const SettingsItemTitle = (props) => (<Text Text-16 {...props}/>);
export const SettingsItemDescription = (props) => (<Text TextThin-14 Gray {...props}/>);
export const SettingsItemSubDescription = (props) => (<Text TextThin-14 Gray {...props}/>);
export const SettingsItemBase = React.memo(({ light, title, children, selected, renderIcon, description, topDescription, disabled, icon: iconNode, iconSize = 40, onPress, style, }) => {
    const childrenArray = React.Children.toArray(children);
    const content = childrenArray.find((node) => node?.type.name === SettingsItemContent.name);
    const rightContent = childrenArray.find((node) => node?.type.name === SettingsItemRightContent.name);
    const colors = useColors();
    const styles = useStyles(settingsItemStyleSet);
    return (<TouchableHighlight onCard hoverOnLight activeOpacity={1} onPress={onPress} disabled={!!disabled || !onPress} underlayColor={colors.background.underlay}>
        <View style={[styles.container, style]}>
          {renderIcon && renderIcon()}
          {iconNode && (<View style={[styles.iconContainer, { maxWidth: rem(iconSize) }]}>
              <SettingsItemIcon iconSize={iconSize} light={light}>
                {iconNode}
              </SettingsItemIcon>
              {!!selected && (<View style={styles.checkmark}>
                  <Squircle width={rem(24)} height={rem(24)} color={colors.cards.onLight}>
                    <Squircle width={rem(20)} height={rem(20)} color={colors.gradients.lightPink.colors[0]}>
                      <IconCheck alt color={colors.cards.onLight} width={10} height={10}/>
                    </Squircle>
                  </Squircle>
                </View>)}
            </View>)}
          {content || (<SettingsItemContent style={styles.contentContainer}>
              {topDescription && (<SettingsItemSubDescription>
                  {topDescription}
                </SettingsItemSubDescription>)}
              <SettingsItemTitle>{title}</SettingsItemTitle>
              {description && (<SettingsItemDescription>{description}</SettingsItemDescription>)}
            </SettingsItemContent>)}
          {rightContent && rightContent}
        </View>
      </TouchableHighlight>);
});
export const SettingsItem = ({ ...props }) => <SettingsItemBase {...props}/>;
export const SettingsItemSwitch = ({ onValueChange, itemKey, value, isFetching, ...props }) => {
    const styles = useStyles(settingsItemStyleSet);
    return (<SettingsItemBase {...props}>
      <SettingsItemRightContent style={styles.switchSettingRight}>
        <View>
          <Switch value={value} onValueChange={(value) => onValueChange?.(value, itemKey)} disabled={Boolean(props.disabled)}/>
          {!!isFetching && (<View style={styles.loadingIndicator}>
              <Indicator brand/>
            </View>)}
        </View>
      </SettingsItemRightContent>
    </SettingsItemBase>);
};
export const SettingsItemNavigate = ({ title, children, description, disabled, onPress, onPressOnDisabled, titleFirst, isSelected, scrambleTitle, scrambleDescription, ...props }) => {
    const colors = useColors();
    const placeholder = typeof title === 'string' && title.endsWith('...');
    const titleRender = placeholder ? (<BlurAmountView enabled={scrambleTitle}>
      <SettingsItemTitle numberOfLines={1} color={colors.text.placeholder}>
        {title}
      </SettingsItemTitle>
    </BlurAmountView>) : (Boolean(title) && (<BlurAmountView enabled={scrambleTitle}>
        <SettingsItemTitle numberOfLines={1}>{title}</SettingsItemTitle>
      </BlurAmountView>));
    const descriptionRender = Boolean(description) && (<BlurAmountView enabled={scrambleDescription}>
      <SettingsItemDescription>{description}</SettingsItemDescription>
    </BlurAmountView>);
    return (<SettingsItemBase {...props} disabled={!!disabled && !onPressOnDisabled} onPress={() => {
            if (!disabled) {
                onPress?.();
            }
            else if (onPressOnDisabled) {
                onPressOnDisabled();
            }
        }}>
      <SettingsItemContent testID="settings-item-navigate-content">
        {children}
        {titleFirst ? (<>
            {titleRender}
            {descriptionRender}
          </>) : (<>
            {descriptionRender}
            {titleRender}
          </>)}
      </SettingsItemContent>
      <SettingsItemRightContent>
        {!disabled && <IconArrow direction={isSelected ? 'down' : 'right'}/>}
      </SettingsItemRightContent>
    </SettingsItemBase>);
};
export const SettingsItemInput = React.forwardRef(({ description, onClearTextInput, style, onPress, ...props }, ref) => {
    const { onChangeText: onChangeTextProp } = props;
    const fallbackTextInputRef = useRef(null);
    const [text, setText] = useState('');
    const onChangeText = useCallback((inputtedText) => {
        setText(inputtedText);
        if (onChangeTextProp) {
            onChangeTextProp(inputtedText);
        }
    }, [onChangeTextProp]);
    const colors = useColors();
    const styles = useStyles(settingsItemStyleSet);
    return (<SettingsItemBase {...props} style={style} onPress={() => {
            if (ref) {
                if ('current' in ref) {
                    ref.current?.focus();
                }
            }
            onPress?.();
        }}>
      <SettingsItemContent>
        <SettingsItemDescription>{description}</SettingsItemDescription>
        <TextInput style={styles.textInput} {...props} onChangeText={onChangeText} ref={ref ?? fallbackTextInputRef} placeholderTextColor={colors.text.placeholder} selectionColor={colors.text.selection} returnKeyType={isDesktop ? undefined : props.returnKeyType}/>
      </SettingsItemContent>
      {(Boolean(props.value) || (props.value === undefined && text !== '')) &&
            props.editable !== false && (<SettingsItemRightContent>
            <TouchableWithoutFeedback hitSlop={hitSlop} onPress={onClearTextInput
                ? () => {
                    if (props.onChangeText) {
                        props.onChangeText('');
                        if (ref) {
                            if ('current' in ref) {
                                ref.current?.focus();
                            }
                        }
                    }
                }
                : () => {
                    props.onChangeText?.('');
                    if (ref) {
                        if ('current' in ref) {
                            ref.current?.clear();
                        }
                    }
                    else if (fallbackTextInputRef.current) {
                        fallbackTextInputRef.current.clear();
                        fallbackTextInputRef.current.focus();
                    }
                }}>
              <View style={styles.closeIconContainer}>
                <IconClose style={styles.closeIcon}/>
              </View>
            </TouchableWithoutFeedback>
          </SettingsItemRightContent>)}
    </SettingsItemBase>);
});
export const SettingsItemInputMask = React.forwardRef(({ description, onClearTextInput, subDescription, customSubDescription, disabled, shouldUsePlaceholderColor, prefix, noMask = false, style, ...props }, ref) => {
    const colors = useColors();
    const styles = useStyles(settingsItemStyleSet);
    return (<SettingsItemBase style={style} {...props} onPress={ref && 'current' in ref
            ? () => {
                if (ref?.current && 'current' in ref) {
                    if ('getElement' in ref.current) {
                        ref.current?.getElement()?.focus();
                    }
                    else {
                        ref.current?.focus();
                    }
                }
            }
            : undefined}>
        <SettingsItemContent>
          <SettingsItemDescription>{description}</SettingsItemDescription>
          <View style={styles.prefixContainer}>
            {prefix && <Text Text-16>{prefix}</Text>}
            {noMask ? (<TextInput style={[
                styles.textInput,
                shouldUsePlaceholderColor && styles.placeholder,
            ]} {...props} selectionColor={colors.text.selection} ref={ref} returnKeyType={isDesktop ? undefined : props.returnKeyType}/>) : (<TextInputMask style={[
                styles.textInput,
                shouldUsePlaceholderColor && styles.placeholder,
            ]} {...props} selectionColor={colors.text.selection} ref={ref} returnKeyType={isDesktop ? undefined : props.returnKeyType}/>)}
          </View>
          {customSubDescription ??
            (Boolean(subDescription) && (<SettingsItemSubDescription>
                {subDescription}
              </SettingsItemSubDescription>))}
        </SettingsItemContent>
        {!disabled && (<SettingsItemRightContent>
            <TouchableWithoutFeedback onPress={onClearTextInput} hitSlop={hitSlop} testID="clear-input">
              <View style={styles.closeIconContainer}>
                <IconClose style={styles.closeIcon}/>
              </View>
            </TouchableWithoutFeedback>
          </SettingsItemRightContent>)}
      </SettingsItemBase>);
});
export const settingsItemStyleSet = createStyleSheets((colors) => ({
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        margin: rem(16),
        ...(isWeb && {
            marginHorizontal: rem(8),
        }),
    },
    contentContainer: {
        flex: 1,
        justifyContent: 'center',
    },
    rightContentContainer: {
        alignItems: 'flex-end',
        minWidth: rem(20),
    },
    iconContainer: {
        flex: 1,
        marginRight: rem(16),
    },
    textInput: {
        fontFamily: 'Montserrat-Regular',
        fontSize: rem(16),
        margin: 0,
        padding: 0,
        color: colors.text.primary,
        ...(isWeb && {
            caretColor: colors.text.selection,
        }),
    },
    placeholder: {
        color: colors.text.placeholder,
    },
    closeIconContainer: {
        padding: rem(8),
        cursor: 'pointer',
        marginRight: -rem(8),
    },
    closeIcon: {
        color: colors.elements.secondary,
    },
    switchSettingRight: {
        marginLeft: rem(12),
    },
    checkmark: {
        right: 0,
        bottom: 0,
        width: rem(24),
        height: rem(24),
        position: 'absolute',
    },
    loadingIndicator: {
        ...StyleSheet.absoluteFillObject,
        left: -1,
        right: -1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.cards.onDark,
    },
    prefixContainer: { flexDirection: 'row', alignItems: 'center' },
}));
