// this is only for web
import React, { useCallback, useMemo, useState } from 'react';
import { View, ScrollView } from 'react-native';
import Text from 'design-system/Text';
import SearchBar from 'design-system/SearchBar';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import useColors from 'hooks/useColors';
import currencies from './currencies';
const CurrencyMenuContent = ({ onSelect, onClose }) => {
    const styles = useStyles(styleSet);
    const colors = useColors();
    const [filteredCurrencies, setFilteredCurrencies] = useState(currencies);
    const onSelectCurrency = useCallback((currency) => {
        onSelect(currency);
        onClose();
    }, [onClose, onSelect]);
    const handleSearch = useCallback((searchText) => {
        const results = currencies.filter((currency) => {
            const searchLower = searchText.toLowerCase();
            return (currency.name.toLowerCase().includes(searchLower) ||
                currency.code.toLowerCase().includes(searchLower));
        });
        setFilteredCurrencies(results);
    }, []);
    const content = useMemo(() => {
        const options = filteredCurrencies.map((currency) => (<TouchableHighlight onCard hoverOnLight key={currency.code} activeOpacity={1} onPress={() => onSelectCurrency(currency.code)} underlayColor={colors.background.underlay}>
        <View style={styles.itemRow}>
          <Text Text-16 OffBlack>
            {currency.name} ({currency.code})
          </Text>
        </View>
      </TouchableHighlight>));
        return (<ScrollView showsVerticalScrollIndicator={false} overScrollMode="never">
        {options}
      </ScrollView>);
    }, [
        colors.background.underlay,
        filteredCurrencies,
        onSelectCurrency,
        styles.itemRow,
    ]);
    return (<View style={styles.container}>
      <SearchBar placeholder="Search" onChangeSearchTerm={handleSearch} containerStyle={styles.searchBarContainer} noAnimation/>
      {content}
    </View>);
};
const styleSet = createStyleSheets(() => ({
    itemRow: {
        flexDirection: 'row',
        paddingVertical: 16,
        paddingHorizontal: 8,
    },
    container: {
        height: 400,
    },
    searchBarContainer: {
        paddingHorizontal: 0,
        paddingTop: 0,
        paddingBottom: 8,
    },
}));
export default CurrencyMenuContent;
