import React, { forwardRef, useCallback, useMemo } from 'react';
import { View } from 'react-native';
import getSymbolFromCurrency from 'currency-symbol-map';
import { TextInputMask } from 'react-native-masked-text';
import { rem } from 'design-system';
import Text, { styles as textStyles } from 'design-system/Text';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import { isWeb } from '../../../constants';
var Sign;
(function (Sign) {
    Sign["Positive"] = "";
    Sign["Negative"] = "-";
})(Sign || (Sign = {}));
const PositiveNegativeBalanceInput = forwardRef(({ sign, currency, value, maxLength, onChangeText, onSubmitEditing, amountPositionRight, smallerTextInput, flex, onDark, }, ref) => {
    const styles = useStyles(styleSet);
    const colors = useColors();
    const [isFocused, setIsFocused] = React.useState(false);
    const handleFocus = useCallback(() => {
        setIsFocused(true);
    }, []);
    const handleBlur = useCallback(() => {
        setIsFocused(false);
    }, []);
    const inputStyle = useMemo(() => [
        styles.textInput,
        amountPositionRight && styles.amountPositionRight,
        smallerTextInput && styles.smallerTextInput,
        flex
            ? {
                flex: 1,
            }
            : {
                width: isWeb ? `${value.length + 4}ch` : `${value.length + 1}ch`,
                ...(isWeb && {
                    minWidth: '6ch',
                }),
            },
    ], [
        styles.textInput,
        styles.amountPositionRight,
        styles.smallerTextInput,
        amountPositionRight,
        smallerTextInput,
        flex,
        value.length,
    ]);
    const prefix = useMemo(() => {
        const symbol = getSymbolFromCurrency(currency);
        return sign === Sign.Negative ? `-${symbol}` : symbol;
    }, [sign, currency]);
    return (<View style={[
            styles.container,
            onDark && styles.onDark,
            isFocused && styles.focusedStyles,
        ]}>
        {!isWeb && sign === Sign.Negative && <Text Numbers-24>-</Text>}
        <TextInputMask ref={ref} style={inputStyle} type="money" allowFontScaling={false} underlineColorAndroid="transparent" options={{
            unit: isWeb ? prefix : getSymbolFromCurrency(currency),
            separator: '.',
            delimiter: ',',
            precision: 2,
        }} keyboardType="number-pad" onChangeText={onChangeText} includeRawValueInChangeText value={value} maxLength={maxLength} selectionColor={colors.text.selection} placeholderTextColor={colors.text.placeholder} onSubmitEditing={onSubmitEditing} onFocus={handleFocus} onBlur={handleBlur}/>
      </View>);
});
const styleSet = createStyleSheets((colors) => ({
    container: {
        borderWidth: rem(1),
        borderRadius: rem(8),
        borderColor: colors.borders.divider,
        backgroundColor: isWeb ? colors.background.dark : colors.cards.onDark,
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'text',
    },
    onDark: {
        backgroundColor: colors.cards.onDark,
    },
    textInput: {
        flexDirection: 'row',
        ...textStyles.Numbers_24,
        color: colors.text.primary,
        textAlign: 'left',
        fontFamily: 'Montserrat-Regular',
        caretColor: colors.text.selection,
        paddingVertical: rem(8),
        paddingHorizontal: rem(16),
    },
    focusedStyles: {
        borderColor: colors.elements.brand,
    },
    amountPositionRight: {
        textAlign: 'right',
    },
    smallerTextInput: {
        ...textStyles.Numbers_20,
    },
}));
export default PositiveNegativeBalanceInput;
