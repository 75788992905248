import React, { useMemo } from 'react';
import { IconBank, IconBox, IconCoinStack, IconHome, IconMoneyBag, } from 'design-system/icons';
import { IconBankCard } from 'design-system/icons/IconBankCard';
import IconCar from 'design-system/icons/IconCar';
import IconGraph from 'design-system/icons/IconGraph';
import { IconPiggy } from 'design-system/icons/IconPiggy';
import useColors from 'hooks/useColors';
export const DUMMY_ACCOUNT_ICON_MAP = {
    REAL_ESTATE: <IconHome gradientKey="orange"/>,
    VEHICLE: <IconCar gradientKey="turquoise"/>,
    OTHER: <IconBox gradientKey="lightPink"/>,
    CHECKING: <IconBank gradientKey="pro"/>,
    CREDITCARD: <IconBankCard gradientKey="darkPurple"/>,
    SAVINGS: <IconPiggy gradientKey="deepPink"/>,
    LOAN: <IconMoneyBag gradientKey="green"/>,
    INVESTMENT: <IconGraph gradientKey="peach"/>,
    PENSION: <IconCoinStack gradientKey="plus"/>,
};
const useDummyAccountLogo = (type, size = 35) => {
    const colors = useColors();
    const icon = useMemo(() => {
        const element = type && DUMMY_ACCOUNT_ICON_MAP[type] ? (DUMMY_ACCOUNT_ICON_MAP[type]) : (<IconBank gradientKey="pro"/>);
        return React.isValidElement(element)
            ? React.cloneElement(element, {
                height: size,
                width: size,
            })
            : null;
    }, [colors.elements.brand, type]);
    return icon;
};
export default useDummyAccountLogo;
