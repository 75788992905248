import React from 'react';
import { StyleSheet, View } from 'react-native';
import Squircle from 'design-system/Squircle';
import Text from 'design-system/Text';
import useColors from 'hooks/useColors';
import { rem } from 'design-system';
const SQUIRCLE_SIZE = 64;
export const AddAccountHeader = ({ icon }) => {
    const colors = useColors();
    return (<View style={styles.container}>
      <Squircle width={SQUIRCLE_SIZE} height={SQUIRCLE_SIZE} color={colors.cards.onDark} style={styles.squircle}>
        {icon}
      </Squircle>
      <View style={styles.textContainer}>
        <Text Secondary TextThin-20>
          What’s the current balance for this account?
        </Text>
      </View>
    </View>);
};
const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        marginBottom: rem(24),
    },
    squircle: {
        marginBottom: rem(16),
    },
    textContainer: {
        alignItems: 'center',
    },
});
export default AddAccountHeader;
