import React, { useState } from 'react';
import { View } from 'react-native';
import Modal from 'design-system/ModalWeb';
import Squircle from 'design-system/Squircle';
import useColors from 'hooks/useColors';
import { IconWork } from 'design-system/icons';
import { IconWifi } from 'design-system/icons/IconWifi';
import IconProperty from 'design-system/icons/IconProperty';
import IconInvest from 'design-system/icons/IconInvest';
import { IconBankCard } from 'design-system/icons/IconBankCard';
import { CardView } from 'design-system/CardView';
import Spacer from 'design-system/Spacer';
import createStyleSheets from 'utils/createStyleSheets';
import { useAppSelector } from 'store/hooks';
import useStyles from 'hooks/useStyles';
import { selectIsGBUser } from 'reducers/selectors';
import TapableCard from './TapableCard';
import { UnavailableAccountModal, AccountType, } from './UnavailableAccountModal.web';
const AddAccountWebModalContent = ({ options }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    const isGBUser = useAppSelector(selectIsGBUser);
    const [state, setState] = useState({
        isOpen: false,
        options,
    });
    const handleAddPersonalAndBusinessAccount = (type) => {
        AddAccountWebModal.hide();
        UnavailableAccountModal.show(type);
    };
    return (<View>
      <Spacer h={16}/>
      <CardView noMargin style={styles.container}>
        <TapableCard title="Personal" subtitle={isGBUser
            ? 'Current, Savings, Credit Cards and Investments'
            : 'Current, Savings, and Credit Cards'} IconComponent={() => (<Squircle color={colors.background.dark}>
              <IconBankCard color={colors.gradients.green.colors[0]}/>
            </Squircle>)} onPress={() => handleAddPersonalAndBusinessAccount(AccountType.personal)}/>
        {isGBUser && (<TapableCard title="Business" subtitle={'Current, Savings and Credit\xa0Cards'} IconComponent={() => (<Squircle color={colors.background.dark}>
                <IconWork gradientKey="deepPink"/>
              </Squircle>)} onPress={() => handleAddPersonalAndBusinessAccount(AccountType.business)}/>)}
        <TapableCard title="Offline Account" subtitle={'Track your cash and accounts\xa0not\xa0in\xa0Emma'} IconComponent={() => (<Squircle color={colors.background.dark}>
              <IconWifi off gradientKey="ultimate"/>
            </Squircle>)} onPress={() => {
            AddAccountWebModal.hide();
            setState((o) => ({ ...o, isOpen: false }));
            state.options?.onAddOffline?.();
        }}/>
        {!isGBUser && (<TapableCard title="Investments" subtitle="Stocks & Crypto" IconComponent={() => (<Squircle color={colors.background.dark}>
                <IconInvest gradientKey="apricot"/>
              </Squircle>)} onPress={() => { }}/>)}
        {state.options?.onAddProperty && (<TapableCard title="Property" subtitle="Make your net worth accurate" IconComponent={IconProperty} onPress={() => {
                AddAccountWebModal.hide();
                setState((o) => ({ ...o, isOpen: false }));
                state.options?.onAddProperty?.();
            }}/>)}
      </CardView>
    </View>);
};
const AddAccountWebModal = {
    show: (options) => {
        Modal.show(<AddAccountWebModalContent options={options}/>, {
            title: 'Add Account',
            lightBg: true,
            titleBottomBorder: true,
        });
    },
    hide: () => {
        Modal.hide();
    },
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        flex: 1,
        padding: 8,
        backgroundColor: colors.background.light,
    },
}));
export default AddAccountWebModal;
