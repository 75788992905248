import React, { useCallback, useLayoutEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import Animated from 'react-native-reanimated';
import { format, isBefore, isAfter, formatISO, isSameDay } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import Button from 'design-system/Button';
import StatusBar from 'design-system/StatusBar';
import { AppView } from 'design-system/AppView';
import { Day } from 'design-system/calendar/Day';
import { ButtonGroup } from 'design-system/ButtonGroup';
import ScreenInfoHeader from 'design-system/ScreenInfoHeader';
import { rem, SCROLL_INDICATOR_INSETS } from 'design-system/values';
import { Calendar } from 'design-system/calendar/Calendar';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import useColors from 'hooks/useColors';
import { useMarginBottom } from 'hooks/useMarginBottom';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { isWeb } from '../../constants';
const FORMAT = 'MMMM d, yyyy';
const strings = {
    selectDate: 'Select a date',
    defaultButtonTitle: 'Next',
};
export const SelectDateScreen = withAnimatedNavigationHeader(({ navigation, route, scrollHandler }) => {
    const colors = useColors();
    const { longTitle, subTitle, buttonText: buttonTextProps } = route.params;
    const { minDate, maxDate } = route.params.props || {};
    useLayoutEffect(() => {
        navigation.setOptions({
            title: longTitle ? '' : strings.selectDate,
            headerTintColor: colors.text.primary,
        });
    }, [colors.text.primary, longTitle]);
    const [selected, setSelected] = useState(new Date());
    const [buttonText, setButtonText] = useState(format(selected, FORMAT));
    const checkEnabled = useCallback((date) => {
        const currentDate = new Date(date.timestamp);
        if (!minDate && !maxDate) {
            return true;
        }
        if (minDate && maxDate) {
            return (isBefore(currentDate, maxDate) && isAfter(currentDate, minDate));
        }
        if (minDate) {
            return isAfter(currentDate, minDate);
        }
        return maxDate ? isBefore(currentDate, maxDate) : false;
    }, [maxDate, minDate]);
    const onDayPress = useCallback((date) => {
        if (!checkEnabled(date)) {
            return;
        }
        const utcDate = toZonedTime(date.timestamp, 'UTC');
        setSelected(utcDate);
        setButtonText(format(utcDate, FORMAT));
    }, [checkEnabled]);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const paddingBottom = useMarginBottom('paddingBottom', undefined, paddingHorizontalStyle);
    const onSubmit = useCallback(() => {
        const utcDate = new Date(selected.getTime() - selected.getTimezoneOffset() * 60000);
        route.params.onSubmitDate(formatISO(utcDate));
    }, [route.params, selected]);
    return (<AppView withFrame={false}>
          <StatusBar barStyle="dark-content" animated/>
          <Animated.ScrollView contentContainerStyle={paddingBottom} onScroll={scrollHandler} scrollEventThrottle={16} scrollIndicatorInsets={SCROLL_INDICATOR_INSETS}>
            {!!longTitle && (<ScreenInfoHeader smallSubTitle title={longTitle} subTitle={subTitle}/>)}
            <Calendar style={[styles.calendar, !longTitle && { marginTop: rem(8) }]} maxDate={maxDate} dayComponent={({ date }) => (<Day key={date?.dateString} date={date} isStartDay={date
                ? isSameDay(selected, toZonedTime(date.timestamp, 'UTC'))
                : false} onDayPress={onDayPress} isDisabled={date ? !checkEnabled(date) : false}/>)}/>
          </Animated.ScrollView>
          <ButtonGroup type="floating">
            <Button brand onPress={onSubmit} title={buttonTextProps || buttonText}/>
          </ButtonGroup>
        </AppView>);
}, ({ colors }) => ({
    title: isWeb ? 'Select a date' : undefined,
    hasCustomTitle: true,
    headerColorOutputRange: [
        colors.background.dark,
        colors.cards.onDark,
    ],
}));
const styles = StyleSheet.create({
    calendar: {
        ...(!isWeb && {
            alignSelf: 'center',
        }),
        width: '100%',
    },
});
