import React, { useCallback, useState } from 'react';
import { Text, View } from 'react-native';
import FastImage from 'utils/packages/rnFastImage';
import { rem } from 'design-system';
import { IconBank } from 'design-system/icons';
import Logo from 'design-system/Logo';
import IconCategoryExcluded from 'features/transactions/icons/categories/IconCategoryExcluded';
import { extractImage } from 'utils/imageHelpers';
import createStyleSheets from 'utils/createStyleSheets';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import Squircle from './Squircle';
export const CIRCLE_SIZE = 44;
const CIRCLE_BORDER_SIZE = 1;
const SQUARE_SIZE = 40;
const INNER_SIZE = SQUARE_SIZE - rem(8);
/**
 * If you need to show this within a card, use `onCard`, which makes logo icons use darker background in dark mode.
 */
const LogoBankAccount = ({ data, square, white, onCard, showHidden = false, }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    const [hasError, setHasError] = useState(false);
    const onError = useCallback(() => {
        setHasError(true);
    }, []);
    if ('emoji' in data && data.emoji) {
        return (<View style={[
                styles.container,
                onCard && styles.onCard,
                ...(square
                    ? [
                        styles.squareContainer,
                        {
                            backgroundColor: data.color || white
                                ? colors.background.light
                                : colors.background.dark,
                        },
                    ]
                    : []),
            ]}>
        <Text style={styles.emoji}>{data.emoji}</Text>
      </View>);
    }
    const source = extractImage(data, showHidden);
    if (square) {
        return (<View style={[
                styles.squareContainer,
                {
                    backgroundColor: white
                        ? colors.background.light
                        : colors.background.dark,
                },
            ]}>
        {!source || hasError || typeof source === 'number' ? (<IconBank color={colors.text.negative}/>) : (<FastImage style={styles.squareIcon} source={source}/>)}
      </View>);
    }
    if (showHidden && 'isHidden' in data && data.isHidden) {
        return (<Squircle color={onCard ? colors.background.dark : colors.background.light} width={rem(CIRCLE_SIZE)} height={rem(CIRCLE_SIZE)}>
        <IconCategoryExcluded width={rem(24)} height={rem(24)} fill={colors.text.secondary} viewBox="0 0 24 24"/>
      </Squircle>);
    }
    if (!source || hasError || typeof source !== 'object') {
        return (<View style={styles.fallbackContainer}>
        <IconBank colorKey="brand"/>
      </View>);
    }
    return <Logo R44 uri={source.uri} onError={onError}/>;
};
const styleSet = createStyleSheets((colors) => ({
    container: {
        width: rem(CIRCLE_SIZE),
        height: rem(CIRCLE_SIZE),
        borderRadius: rem(22),
        backgroundColor: colors.cards.onLight,
        justifyContent: 'center',
        alignItems: 'center',
    },
    onCard: {
        backgroundColor: colors.background.dark,
    },
    emoji: {
        color: 'black',
        fontSize: rem(25),
        fontFamily: 'System',
        textAlign: 'center',
    },
    squareContainer: {
        width: rem(SQUARE_SIZE),
        height: rem(SQUARE_SIZE),
        borderRadius: rem(8),
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        overflow: 'hidden',
    },
    squareIcon: {
        width: rem(INNER_SIZE),
        height: rem(INNER_SIZE),
    },
    fallbackContainer: {
        width: rem(CIRCLE_SIZE),
        height: rem(CIRCLE_SIZE),
        borderWidth: CIRCLE_BORDER_SIZE,
        borderColor: colors.cards.transparentOnDark,
        borderRadius: rem(CIRCLE_SIZE / 2),
        backgroundColor: colors.background.logo,
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
export default LogoBankAccount;
