import { format } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import { Animated, Easing, StyleSheet, View } from 'react-native';
import Text from 'design-system/Text';
import Button from 'design-system/Button';
import Spacer from 'design-system/Spacer';
import { rem } from 'design-system/values';
import IconRepeat from 'design-system/icons/IconRepeat';
import useColors from 'hooks/useColors';
import Amplitude from 'utils/amplitude';
import { useAppSelector } from 'store/hooks';
import useForceSync from 'hooks/useForceSync';
import { selectIsSyncingManual } from 'reducers/selectors';
import OverviewAddButton from './OverviewAddButton';
import { usePreviousLastSync } from './hooks/usePreviousLastSync';
const selectLastChange = (store) => store.user.lastChange;
const OverviewLastSynced = () => {
    const lastSuccessfulSync = useAppSelector(selectLastChange);
    const isSyncingManual = useAppSelector(selectIsSyncingManual);
    const [isFetchingSyncStatus, forceSync] = useForceSync();
    const [spinValue] = useState(new Animated.Value(0));
    const isSpinning = isFetchingSyncStatus || isSyncingManual;
    const spinAnim = useRef(Animated.timing(spinValue, {
        toValue: 1,
        duration: 1000,
        easing: Easing.linear,
        useNativeDriver: true,
        isInteraction: false,
    }));
    const spin = useCallback(() => {
        spinValue.setValue(0);
        spinAnim.current.start(({ finished }) => {
            if (isSpinning && finished) {
                spin();
            }
        });
    }, [isSpinning]);
    useEffect(() => {
        if (isSpinning) {
            spin();
        }
        else {
            spinValue.setValue(0);
            spinAnim.current.reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSpinning]);
    const prevLastSync = usePreviousLastSync(lastSuccessfulSync);
    const formattedDateTime = useMemo(() => {
        const date = lastSuccessfulSync || prevLastSync;
        return date ? format(new Date(date), 'dd MMM h:mmaaa') : undefined;
    }, [prevLastSync, lastSuccessfulSync]);
    const onPressSync = useCallback(async () => {
        forceSync();
        Amplitude.logEvent('Feed.OnRefreshing');
    }, [forceSync]);
    const colors = useColors();
    return (<View style={styles.container}>
      <View style={styles.labelContainer}>
        <OverviewAddButton />
        <Spacer w={8}/>
        <Button brandBorder cards title="Sync" onPress={onPressSync} leftIcon={<Animated.View style={[
                styles.iconLeft,
                {
                    transform: [
                        {
                            rotate: spinValue.interpolate({
                                inputRange: [0, 1],
                                outputRange: ['0deg', '360deg'],
                            }),
                        },
                    ],
                },
            ]}>
              <IconRepeat width={rem(16)} height={rem(16)} color={colors.text.textOnBrand}/>
            </Animated.View>}/>
      </View>
      <View style={styles.labelContainer}>
        <Text TextThin-14 Gray>
          {formattedDateTime}
        </Text>
      </View>
    </View>);
};
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        marginVertical: rem(16),
        marginHorizontal: rem(16),
        justifyContent: 'space-between',
    },
    labelContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconLeft: {
        marginRight: -4,
    },
});
export default OverviewLastSynced;
