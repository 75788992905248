import React, { memo } from 'react';
import Animated, { withTiming, useAnimatedStyle, } from 'react-native-reanimated';
import Svg, { Path } from 'react-native-svg';
import { rem } from 'design-system/values';
import useStyles from 'hooks/useStyles';
import useColors from 'hooks/useColors';
import createStyleSheets from 'utils/createStyleSheets';
const WIDTH = rem(7);
const Pointer = ({ selectedInfoObj, height }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    const finalHeight = height - 8; // no rem required here
    const animatedStyles = useAnimatedStyle(() => ({
        opacity: withTiming(!selectedInfoObj.value ? 0 : 1),
        transform: [
            {
                translateX: selectedInfoObj.value?.x !== undefined
                    ? selectedInfoObj.value?.x
                    : -100,
            },
        ],
    }), [selectedInfoObj]);
    return (<Animated.View pointerEvents="none" style={[styles.container, animatedStyles]}>
      <Svg width={WIDTH} height={finalHeight} preserveAspectRatio="none" viewBox={`0 0 ${WIDTH} ${finalHeight}`}>
        <Path strokeWidth={1} d={`M4 0 l0 ${finalHeight}`} stroke={colors.elements.secondary}/>
      </Svg>
    </Animated.View>);
};
export default memo(Pointer);
const styleSet = createStyleSheets(() => ({
    container: {
        top: 4,
        bottom: 4,
        width: WIDTH,
        left: -rem(4),
        position: 'absolute',
    },
}));
