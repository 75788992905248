import React, { memo, useCallback } from 'react';
import Animated, { withTiming, useAnimatedStyle, } from 'react-native-reanimated';
import { View } from 'react-native';
import TouchableBounce from 'utils/packages/TouchableBounce';
import Text from 'design-system/Text';
import { hitSlop, rem } from 'design-system/values';
import { triggerTimePeriodChange } from 'utils/hapticFeedback';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import useColors from 'hooks/useColors';
const TimePeriod = ({ period, isSelected, selectedPeriod, setSelectedPeriod, darkActiveIndicator, }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    const animatedTextStyle = useAnimatedStyle(() => ({
        opacity: withTiming(period === selectedPeriod || isSelected ? 0 : 1),
    }), [isSelected, period, selectedPeriod]);
    const handlePeriodSelect = useCallback(() => {
        triggerTimePeriodChange();
        setSelectedPeriod(period);
    }, [period, setSelectedPeriod]);
    return (<View style={styles.container}>
      <TouchableBounce hitSlop={hitSlop} style={styles.periodBtn} onPress={handlePeriodSelect} hoverStyle={styles.hoverStyles}>
        <Text Text-14 {...(darkActiveIndicator
        ? { color: colors.background.light }
        : { White: true })}>
          {period}
        </Text>
      </TouchableBounce>
      <View style={styles.absView} pointerEvents="none">
        <Animated.View style={animatedTextStyle}>
          <Text Text-14 Secondary>
            {period}
          </Text>
        </Animated.View>
      </View>
    </View>);
};
export default memo(TimePeriod);
const styleSet = createStyleSheets((colors) => ({
    container: {
        paddingVertical: rem(16),
    },
    periodBtn: {
        width: rem(36),
        height: rem(25),
        alignItems: 'center',
        borderRadius: rem(8),
        justifyContent: 'center',
    },
    absView: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
    },
    hoverStyles: {
        backgroundColor: colors.control.hoverOnLight,
    },
}));
