import { URL } from 'utils/packages/rnUrlPolyfill';
const validProtocols = ['emma:', 'https:'];
function isValidProtocol(protocol) {
    return validProtocols.includes(protocol);
}
export var DeepLinkType;
(function (DeepLinkType) {
    DeepLinkType["NONE"] = "NONE";
    DeepLinkType["LOGIN_SUCCESS"] = "LOGIN_SUCCESS";
    DeepLinkType["LOGIN_FAIL"] = "LOGIN_FAIL";
    DeepLinkType["LOGIN_ABORT"] = "LOGIN_ABORT";
    DeepLinkType["EMAIL_VERIFY"] = "EMAIL_VERIFY";
    DeepLinkType["PAYMENT_SUCCESS"] = "PAYMENT_SUCCESS";
    DeepLinkType["PAYMENT_FAIL"] = "PAYMENT_FAIL";
    DeepLinkType["PAYMENT_ABORT"] = "PAYMENT_ABORT";
    DeepLinkType["VRP_CONSENT_SUCCESS"] = "VRP_CONSENT_SUCCESS";
    DeepLinkType["VRP_CONSENT_ABORT"] = "VRP_CONSENT_ABORT";
    DeepLinkType["VRP_CONSENT_FAIL"] = "VRP_CONSENT_FAIL";
    DeepLinkType["APP_LINK"] = "APP_LINK";
    DeepLinkType["WEB_SIGN_IN"] = "WEB_SIGN_IN";
    DeepLinkType["CHOOSE_BANK"] = "CHOOSE_BANK";
})(DeepLinkType || (DeepLinkType = {}));
/**
 * Parses emma:// and https://emma-app.com/ deeplinks
 * If this function grows too large, extract feature-specific handlers into modules
 *
 * @param deepLink string
 * @returns DeepLinkData
 */
export const parseDeepLink = (deepLink) => {
    const [protocol, rest] = deepLink.split('//');
    if (!isValidProtocol(protocol)) {
        // log error
        return {
            type: DeepLinkType.NONE,
        };
    }
    let url;
    try {
        url = new URL(
        // URL() parses urls with custom (like emma://) and regular (like https://) protocols differently.
        // This converts emma:// links to https:// ones for uniform parsing
        protocol !== 'https:' ? `https://emma-app.com/${rest ?? ''}` : deepLink);
    }
    catch (e) {
        // log error
        return {
            type: DeepLinkType.NONE,
        };
    }
    const { pathname, searchParams } = url;
    if (pathname === '/dashboard/email/verify') {
        const token = searchParams.get('token');
        if (token) {
            return {
                type: DeepLinkType.EMAIL_VERIFY,
                token,
            };
        }
        return {
            type: DeepLinkType.NONE,
        };
    }
    // Login callbacks
    if (pathname === '/loginsuccess' && protocol === 'emma:') {
        return {
            type: DeepLinkType.LOGIN_SUCCESS,
        };
    }
    if (pathname === '/loginfail' && protocol === 'emma:') {
        const error = searchParams.get('error');
        const userMessage = searchParams.get('userMessage');
        return {
            type: DeepLinkType.LOGIN_FAIL,
            error,
            userMessage,
        };
    }
    if (pathname === '/choose-bank' && protocol === 'emma:') {
        const accountType = searchParams.get('type');
        if (accountType) {
            return {
                type: DeepLinkType.CHOOSE_BANK,
                accountType: accountType,
            };
        }
    }
    if (pathname === '/loginabort' && protocol === 'emma:') {
        return {
            type: DeepLinkType.LOGIN_ABORT,
        };
    }
    if (protocol === 'emma:') {
        switch (pathname) {
            // Payment callbacks
            case '/paymentsuccess':
                return {
                    type: DeepLinkType.PAYMENT_SUCCESS,
                };
            case '/paymentfail':
                return {
                    type: DeepLinkType.PAYMENT_FAIL,
                };
            case '/paymentabort':
                return {
                    type: DeepLinkType.PAYMENT_ABORT,
                };
            case '/vrp-consent-success':
                return {
                    type: DeepLinkType.VRP_CONSENT_SUCCESS,
                };
            case '/vrp-consent-fail':
                return {
                    type: DeepLinkType.VRP_CONSENT_FAIL,
                };
            case '/vrp-consent-abort':
                return {
                    type: DeepLinkType.VRP_CONSENT_ABORT,
                };
            case '/web-sign-in':
                const clientId = searchParams.get('clientId');
                if (clientId) {
                    return {
                        type: DeepLinkType.WEB_SIGN_IN,
                        clientId,
                    };
                }
                break;
            default:
                break;
        }
    }
    return {
        routeStr: url.pathname + url.search,
        type: DeepLinkType.APP_LINK,
    };
};
